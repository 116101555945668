import styled from "styled-components/macro";
import { Box, Heading, Button, Paragraph } from "~styled";

const Hero = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    height: 350vh;
  }
  @media (max-width: 469px) {
    height: 330vh;
  }  
  height: 140vh;
`;

Hero.Content = styled(Box)``;
Hero.Subtitle = styled(Heading)`
  font-size: 21px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: normal;
  line-height: 1.4285;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
`;
Hero.Title = styled(Heading)`
  font-size: 30px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 25px;
  text-align: center;
  @media (min-width: 480px) {
    font-size: 40px;
  }

  @media (min-width: 768px) {
    font-size: 50px;
  }
`;
Hero.Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 0;
`;

Hero.Buttons = styled(Box)`
  .btn {
    margin-right: 18px;
  }
`;
Hero.Video = styled(Box)`
  box-shadow: 0 10px 56px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  img {
    max-height: 285px;
  }
  .video-btn {
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    background-color: #5034fc;
    border-color: #5034fc;
    color: #fff;
    box-shadow: 0 0 0 0.2rem rgb(106 82 252 / 50%);
    outline: none;
    border-radius: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
  }
`;
Hero.Button = styled(Button)`
  min-width: 130px;
  height: 50px;
  font-size: 14px;
  transition: 0.4s;
  border-radius: 500px;
  &.btn-1 {
    box-shadow: -12px 12px 50px rgb(96 1 211 / 30%);
    &:hover {
      box-shadow: -12px 12px 50px rgb(96 1 211 / 0%);
    }
  }
  @media (min-width: 576px) {
    min-width: 180px;
    height: 56px;
    font-size: 15px;
  }
`;

export default Hero;
