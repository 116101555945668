import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Hero from "./style";
import Video from "~components/VideoModal";
import Video1 from "../../../../assets/image/landing/tutoriales/video1.png";
import Video2 from "../../../../assets/image/landing/tutoriales/realizarFondeo.png";
import Video3 from "../../../../assets/image/landing/tutoriales/renewald.png";
import { Link } from "~components";

export default function HeroSection({ ...rest }) {

  return (
    <Hero backgroundColor="#fff" {...rest}>

        <Hero.Title as="h2" fontColor="">
          Tutoriales
        </Hero.Title>
      <div style={{ padding: "3rem" }}>
        <Container>
          <Row>
            <Col xs={12} md={4} className="mb-4">
                <Hero.Video
                  className="welcome-img welcome-img--l9 video-box"
                  mb="30px"
                  mbLG="0"
                >
                  <img
                    src={Video1}
                    style={{ width: "100%", borderRadius: "25px" }}
                    alt="Add Contract"
                  ></img>
                  {/* Video Button */}
                  <Video id="LWZ7iytIA6k" className="video-btn">
                    <i className="fa fa-play" />
                  </Video>
                </Hero.Video>
                <Hero.Content>
                  <Hero.Subtitle as="h4" fontColor="rgb(80, 52, 252)">
                    ¿Cómo agregar un contrato?
                  </Hero.Subtitle>
                  <Hero.Text>
                    When, while lovely valley teems with vapour around
                    <br className="d-none d-xs-block" /> meand meridian sun
                    strikes the upper impenetroble
                    <br className="d-none d-xs-block" /> foliage of my trees,
                    and but a thousand.
                  </Hero.Text>
                </Hero.Content>
            </Col>
            <Col xs={12} md={4} className="mb-4">
                <Hero.Video
                  className="welcome-img welcome-img--l9 video-box"
                  mb="30px"
                  mbLG="0"
                >
                  <img
                    src={Video2}
                    style={{ width: "100%", borderRadius: "25px" }}
                    alt="Image Fondeo"
                  ></img>
                  {/* Video Button */}
                  <Video id="LWZ7iytIA6k" className="video-btn">
                    <i className="fa fa-play" />
                  </Video>
                </Hero.Video>
                <Hero.Content>
                  <Hero.Subtitle as="h4" fontColor="rgb(80, 52, 252)">
                    ¿Cómo realizar un fondeo?
                  </Hero.Subtitle>
                  <Hero.Text>
                    When, while lovely valley teems with vapour around
                    <br className="d-none d-xs-block" /> meand meridian sun
                    strikes the upper impenetroble
                    <br className="d-none d-xs-block" /> foliage of my trees,
                    and but a thousand.
                  </Hero.Text>
                </Hero.Content>
            </Col>
            <Col xs={12} md={4} className="mb-4">
                <Hero.Video
                  className="welcome-img welcome-img--l9 video-box"
                  mb="30px"
                  mbLG="0"
                >
                  <img
                    src={Video3}
                    style={{ width: "100%", borderRadius: "25px" }}
                    alt="Image desempeñar"
                  ></img>
                  {/* Video Button */}
                  <Video id="LWZ7iytIA6k" className="video-btn">
                    <i className="fa fa-play" />
                  </Video>
                </Hero.Video>
                <Hero.Content>
                  <Hero.Subtitle as="h4" fontColor="rgb(80, 52, 252)">
                    ¿Cómo desempeñar un artículo?
                  </Hero.Subtitle>
                  <Hero.Text>
                    When, while lovely valley teems with vapour around
                    <br className="d-none d-xs-block" /> meand meridian sun
                    strikes the upper impenetroble
                    <br className="d-none d-xs-block" /> foliage of my trees,
                    and but a thousand.
                  </Hero.Text>
                </Hero.Content>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} className="mb-4">
                <Hero.Video
                  className="welcome-img welcome-img--l9 video-box"
                  mb="30px"
                  mbLG="0"
                >
                  <img
                    src={Video1}
                    style={{ width: "100%", borderRadius: "25px" }}
                    alt="Add Contract"
                  ></img>
                  {/* Video Button */}
                  <Video id="LWZ7iytIA6k" className="video-btn">
                    <i className="fa fa-play" />
                  </Video>
                </Hero.Video>
                <Hero.Content>
                  <Hero.Subtitle as="h4" fontColor="rgb(80, 52, 252)">
                    ¿Cómo agregar un contrato?
                  </Hero.Subtitle>
                  <Hero.Text>
                    When, while lovely valley teems with vapour around
                    <br className="d-none d-xs-block" /> meand meridian sun
                    strikes the upper impenetroble
                    <br className="d-none d-xs-block" /> foliage of my trees,
                    and but a thousand.
                  </Hero.Text>
                </Hero.Content>
            </Col>
            <Col xs={12} md={4} className="mb-4">
                <Hero.Video
                  className="welcome-img welcome-img--l9 video-box"
                  mb="30px"
                  mbLG="0"
                >
                  <img
                    src={Video2}
                    style={{ width: "100%", borderRadius: "25px" }}
                    alt="Image Fondeo"
                  ></img>
                  {/* Video Button */}
                  <Video id="LWZ7iytIA6k" className="video-btn">
                    <i className="fa fa-play" />
                  </Video>
                </Hero.Video>
                <Hero.Content>
                  <Hero.Subtitle as="h4" fontColor="rgb(80, 52, 252)">
                    ¿Cómo realizar un fondeo?
                  </Hero.Subtitle>
                  <Hero.Text>
                    When, while lovely valley teems with vapour around
                    <br className="d-none d-xs-block" /> meand meridian sun
                    strikes the upper impenetroble
                    <br className="d-none d-xs-block" /> foliage of my trees,
                    and but a thousand.
                  </Hero.Text>
                </Hero.Content>
            </Col>
            <Col xs={12} md={4} className="mb-4">
              <Hero.Video
                className="welcome-img welcome-img--l9 video-box"
                mb="30px"
                mbLG="0"
              >
                <img
                  src={Video3}
                  style={{ width: "100%", borderRadius: "25px" }}
                  alt="Image desempeñar"
                ></img>
                {/* Video Button */}
                <Video id="LWZ7iytIA6k" className="video-btn">
                  <i className="fa fa-play" />
                </Video>
              </Hero.Video>
              <Hero.Content>
                <Hero.Subtitle as="h4" fontColor="rgb(80, 52, 252)">
                  ¿Cómo desempeñar un artículo?
                </Hero.Subtitle>
                <Hero.Text>
                  When, while lovely valley teems with vapour around
                  <br className="d-none d-xs-block" /> meand meridian sun
                  strikes the upper impenetroble
                  <br className="d-none d-xs-block" /> foliage of my trees, and
                  but a thousand.
                </Hero.Text>
              </Hero.Content>
            </Col>
          </Row>
        </Container>
      </div>
    </Hero>
  );
}
